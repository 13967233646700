
//=============================================================================
// Función Genérica Renee_Main
//=============================================================================

export default async function APICALL(data) {
  const ID = sessionStorage.getItem('environmentMain');
  const ID2 = sessionStorage.getItem('test');
  let isTestEnvironment;
  let URL;

  const options = {
    method: 'POST',
  };

  if (ID === 'Local') {
    URL = 'http://localhost:8070/MainCall';
  } else {
    URL = 'https://api.renee.cl/MainCall';
  }

  if (ID2 === 'Test') {
    isTestEnvironment = window.location.hostname !== 'app.renee.cl';
  } else {
    isTestEnvironment = false;
  }

  if (data instanceof FormData) {
    options.body = data; 
  } else {

    options.headers = { 'Content-Type': 'application/json' };
    options.body = JSON.stringify({ 
      data, 
      environment: isTestEnvironment ? 'test' : 'production' 
    });
    options.mode = 'cors';
  }

  try {
    const response = await fetch(URL, options);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during fetch:', error);
    return { response: '' };
  }
}
