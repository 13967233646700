
//=============================================================================
// Pantalla Recuperación de Respuestas Pre-Screening
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import AICALL from './AICALL';
import LOGCALL from './LOGCALL';
import StarRating from '../components/star';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default function Respuestas() {
  const navigate = useNavigate();

  const [Score, setScore] = useState('0');
  const [FormID, setFormID] = useState('');
  const [CompName, setCompName] = useState('');
  const [SortType, setSortType] = useState('chrono');
  const [FetchedData, setFetchedData] = useState(''); 
  const [SelectedName, setSelectedName] = useState('');
  const [selectedMail, setSelectedMail] = useState('');
  const [SelectedSent, setSelectedSent] = useState(''); 
  const [ScreenType, setScreentype] = useState('preguntas');
  const [Form, setForm] = useState([]); 
  const [CV, setCV] = useState(null);
  const [CVlist, setCVlist] = useState([]);
  const [SelectList, setselectlist] = useState([]);
  const [SelectList2, setselectlist2] = useState([]);
  const [SelectedCandidates, setSelectedCandidates] = useState([]);
  const [Wait, setWait] = useState(false);
  const [ShowPopup, setShowPopup] = useState(false);
  const [ShowPopup4, setShowPopup4] = useState(false);
  const [SelectedCVId, setSelectedCVId] = useState(null);
  const [remainingCandidates, setRemainingCandidates] = useState(0);

  let baseUrl

  if (sessionStorage.getItem('environmentLog') === 'Local') {
    baseUrl = 'http://localhost:3001';
  } else {
    baseUrl = 'https://candidatos.renee.cl';
  }

  async function handleformcheck(name, check) {
  
    try {
      const resp = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: FormID+'_'+name
      });
      if (resp.length > 0) {
        if (check !== true) {
          console.log('consulta a IA')
          try {
            const resp2 = await AICALL({
              apicall: '08',
              name: name,
              data: JSON.stringify(resp)
            });
            const CleanReply = resp2.reply.replace(/^```json|```$/g, "").trim();
            setForm(JSON.parse(CleanReply));
            saveUpdate(name);
            SaveForm(JSON.parse(CleanReply) , name );
          } catch (error) {
            console.error('Error during fetch:', error);
            return [];
          }
        } else {
          console.log('consulta a DB')
          setForm(resp);
        }
        return resp;
      } else {
        setForm(resp);
        return [];
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return [];
    }

  };

  async function handleformSkip(name, check) {
  
    try {
      const resp = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: FormID+'_'+name
      });
        setForm(resp);
        return resp;
    } catch (error) {
      console.error('Error during fetch:', error);
      return [];
    }

  };

  async function HandleMailAll(state) {

    console.log(SelectedCandidates)
    if (FormID) {
      if (state === true) {
        for (const reply of CVlist) {
          if (SelectedCandidates.includes(reply.name)) {
            try {
              await HandleMail(reply.mail, reply.name, state);     
  
            } catch (error) {
              console.error(`Error al enviar mensaje a ${reply.name}`);
            }
          }
        }
      } else {
        for (const reply of CVlist) {
          if (SelectList.includes(reply.name)){
            if (!SelectedCandidates.includes(reply.name)) {
              try {
                await HandleMail(reply.mail, reply.name, state);     
    
              } catch (error) {
                console.error(`Error al enviar mensaje a ${reply.name}`);
              }
            }
          }
        }
      }
    } else {
      window.alert('No se logro encontrar ID asociado al formulario que se intenta enviar.')
    }

    window.alert('Formularios enviados a todos los miembros seleccionados');
    setShowPopup(false);
  };

  async function HandleMail(mail, name, state) {
    let call
    if (!FormID){
      setFormID(FetchedData.FormID)
    }

    if (state === true) {
      call = 'FM_SEND'
    } else {
      call = 'FM_REJ1'
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: name, Rejected: true}
      });
    }
    try {
      await LOGCALL({
        apicall: call,
        code: sessionStorage.getItem('process'),
        Mail : mail,
        Title : FetchedData.Charge_title,
        Name : name,
        E_name: CompName,
        P_name: sessionStorage.getItem('token'),
        P_mail: sessionStorage.getItem('mail'),
        Text : ReplaceSpecialChars(FetchedData.GPToferta)
                .replace(/\*\*(.*?)\*\*/g, '<br/><br/><strong>$1</strong><br/><br/>')
                // eslint-disable-next-line
                .replace(/\~(.*?)\~/g, '<li>$1<br/>'),
        FormID : FormID
      });
      saveCV(name);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  async function handleRatingChange (newRating) {
    setScore(newRating);
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === SelectedCVId
          ? { ...cv, stars2: newRating} 
          : cv
      )
    );
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: SelectedCVId, stars2: newRating}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

  };

  async function Handleselect(name) {
    const confirmation = window.confirm(`¿Estás seguro de que deseas remover a ${name} de esta etapa?`);

    if (confirmation) {
      setCVlist(prevCVlist =>
        prevCVlist.map(cv =>
          cv.name === name
            ? { ...cv, select: !cv.select} 
            : cv
        )
      );
    
      const updatedSelectList = SelectList.includes(name)
        ? SelectList.filter(id => id !== name)
        : [...SelectList, name];
    
      setselectlist(updatedSelectList);
    
      try {
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.getItem('process'),
          U_emp: sessionStorage.getItem('Grupo'),
          SelectList: updatedSelectList
        });
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
    }
  };

  async function Handleselect2(name) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select2: !cv.select2} 
          : cv
      )
    );
  
    const updatedSelectList2 = SelectList2.includes(name)
      ? SelectList2.filter(id => id !== name)
      : [...SelectList2, name];
  
    setselectlist2(updatedSelectList2);
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList2: updatedSelectList2
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function CandidatesData(CVlist) {
    const filteredCVlist = CVlist.filter(candidate => SelectList.includes(candidate.name));
    const candidateData = [];
    let headers = ['Nombre', 'Email', 'RUT']; 
    let maxColumnsCandidate = null;
  
    for (const candidate of filteredCVlist) {
      const formData = await handleformSkip(candidate.name, candidate.FormUpdated);
      if (!formData.error && formData.length > (maxColumnsCandidate?.formData?.length || 0)) {
        maxColumnsCandidate = { candidate, formData };
      }
    }
  
    if (maxColumnsCandidate) {
      maxColumnsCandidate.formData.forEach((item, idx) => {
        headers.push(item.title || `Pregunta ${idx + 1}`);
      });setRemainingCandidates(prev => Math.max(prev - 1, 0));
  
      for (const candidate of filteredCVlist) {
        const formData = await handleformcheck(candidate.name, candidate.FormUpdated);
  
        const formattedData = {
          Nombre: candidate.name,
          Email: candidate.mail || ' - - - ',
          RUT: candidate.rut || ' - - - ',
        };
  
        headers.slice(3).forEach((header, idx) => {
          const question = formData[idx];
          formattedData[header] =
            question &&
            (question.type === 'Alt Multiple' || question.type === 'Alt Unica') &&
            question.alternatives
              ? question.alternatives.filter(alt => alt.selected).map(alt => alt.label).join(', ') || ' - - - '
              : question?.content || ' - - - ';
        });
  
        candidateData.push(formattedData);
        setRemainingCandidates(prev => Math.max(prev - 1, 0));
      }
    }
  
    const formattedDataForExcel = [headers];
    candidateData.forEach(dataRow => {
      const row = headers.map(header => dataRow[header] || ' - - - '); 
      formattedDataForExcel.push(row);
    });
  
    return formattedDataForExcel;
  };
  
  async function exportToExcel(CVlist) {
    setWait(true);
    setRemainingCandidates(SelectList.length); 
  
    const compiledData = await CandidatesData(CVlist);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Todos los Candidatos');
  
    // Encabezados
    const headers = compiledData[0];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell(cell => {
      cell.font = { name: 'Arial', size: 12, bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
  
    for (let i = 1; i < compiledData.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 100)); 
      const row = worksheet.addRow(compiledData[i]);
      row.eachCell(cell => {
        cell.alignment = { vertical: 'top' };
      });
    }
  
    // Ajustar ancho de columnas
    headers.forEach((header, index) => {
      worksheet.getColumn(index + 1).width = 40;
    });
  
    // Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    const fileName = `Respuestas_${sessionStorage.getItem('name')}.xlsx`;
    saveAs(new Blob([buffer]), fileName);
  
    setWait(false);
  };
  
  async function handleReneeopinion( Form , name ) {

    const instruction = 'EL Perfil: ' + sessionStorage.getItem('currentprofile') 
                 + '. \n El Formulario: ';

    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, Nota2: null } 
          : cv
      )
    );

    try {
      const consulta = await AICALL({
        apicall: '07',
        data: instruction 
              + JSON.stringify(Form)
      });

      const parsedconsulta = JSON.parse(consulta.reply)
      
      setCVlist(prevCVlist =>
        prevCVlist.map(cv =>
          cv.name === name
            ? { ...cv, Nota2: parsedconsulta.Evaluacion } 
            : cv
        )
      );
      return parsedconsulta.Evaluacion
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    
  };

  async function saveCV( Namedata , score ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: Namedata, Nota2: score}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function saveUpdate( Namedata ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: Namedata, FormUpdated: true}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

    const data2 = await APICALL({
      apicall: 'PD_fetch',
      code: sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo')
    });

    if (data2.CVData){
      setCVlist(data2.CVData);
    }
  };

  async function LookCV( data ) {

    const apiCallType = data.includes('CVE_') ? 'CV_fetch' : 'TB_fetch';


    try {
      const reply = await APICALL({
        apicall: apiCallType,
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        id_code: data
      });

      function replaceNullStrings(obj) {
        for (const key in obj) {
          if (obj[key] === 'Null' || obj[key] === null) {
            obj[key] = ' - - -';
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceNullStrings(obj[key]);
          }
        }
        return obj;
      }
      
      let profile 

      if (apiCallType === 'CV_fetch'){
        profile = replaceNullStrings(reply.data)
      } else {
        profile = reply.data
      }

      const { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments } = profile;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments };

      setCV(cv);
  
    } catch (error) {
      console.error('Error during fetch:', error);
      setCV(null)
    }

  };

  const AddPregunta = () => {
    const newNotes = [
      { title: 'Motivacion', content: '', type: 'TextoNotas' },
      { title: 'Edad', content: '', type: 'TextoNotas' },
      { title: 'Entorno Personal ( Familia, Ambientes Sociales, Etc... )', content: '', type: 'TextoNotas' },
      { title: 'Lugar de Residencia', content: '', type: 'TextoNotas' },
      { title: 'Notas de la entrevista', content: '', type: 'TextoNotas' },
      { title: 'Fortalezas del candidato', content: '', type: 'TextoNotas' },
      { title: 'Areas de Mejora u Oportunidades', content: '', type: 'TextoNotas' },
      { title: 'Notas Adicionales', content: '', type: 'TextoNotas' },
    ];
    setForm((prevForm) => [...prevForm, ...newNotes]);
  };

  const AddNota = () => {
    const newNotes = [
      { title: 'Observacion Adicional', content: '', type: 'TextoNotas' }
    ];
    setForm((prevForm) => [...prevForm, ...newNotes]);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('URL copiada al portapapeles!');
      })
      .catch(err => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };

  const DeletePreunta = (indexToRemove) => {
    const updatedForm = Form.filter((_, index) => index !== indexToRemove);
    setForm(updatedForm);
  };

  const handleInputChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };

  const handleCheckboxChange = (blockIndex, optionIndex, isChecked) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      updatedForm[blockIndex].alternatives[optionIndex].selected = isChecked;
      return updatedForm;
    });
  };

  const handleRadioChange = (blockIndex, selectedOptionIndex) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      updatedForm[blockIndex].alternatives = updatedForm[blockIndex].alternatives.map((alt, optIndex) => ({
        ...alt,
        selected: optIndex === selectedOptionIndex
      }));
      return updatedForm;
    });
  };

  const ReplaceSpecialChars = (text) => {
    const replacements = {
      'á': '&aacute;',
      'é': '&eacute;',
      'í': '&iacute;',
      'ó': '&oacute;',
      'ú': '&uacute;',
      'Á': '&Aacute;',
      'É': '&Eacute;',
      'Í': '&Iacute;',
      'Ó': '&Oacute;',
      'Ú': '&Uacute;',
      'ñ': '&ntilde;',
      'Ñ': '&Ntilde;',
      'ü': '&uuml;',
      'Ü': '&Uuml;',
    };
    return text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, match => replacements[match]);

  };

  async function SaveForm ( form , name) {
    setWait(true)

    await APICALL({
      apicall: 'FM_submmit',
      code: sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo'),
      FormID: FormID ,
      Name: name,
      Formreply: form
    });
    setTimeout(() => {
      setWait(false);
    }, 250);
  };

  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA; 
      } else if (SortType === 'stars') {
        const starsA = a.stars ? a.stars : 0;
        const starsB = b.stars ? b.stars : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });

    if (SortType === 'chrono') {
      return CVlist;
    } else {
      return sortedList;
    }
  };

  const FormatJobData = (jobData) => {
    const title = '<h2>Experiencia Laboral:</h2>\n\n';
  
    const sortedJobs = jobData.slice().sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));
  
    const formattedJobs = sortedJobs.map(job => {
      const achievements = job.achievements.includes('\n?') 
        ? job.achievements.split('\n?').filter(line => line.trim() !== '') 
        : job.achievements.split('\n').filter(line => line.trim() !== '');
  
      const formattedAchievements = achievements.map(achievement => `🞄 ${achievement}`).join('<br>');
  
      return `
        <strong>${job.jobPosition ? job.jobPosition.toUpperCase() : ''}</strong> ${job.companyName} || ${job.companyActivity ? job.companyActivity.description : ''} <br> ${job.fromDate} - ${job.toDate || 'Presente'}
        <br> ${formattedAchievements}
      `.trim();
    }).join('\n\n ________ \n\n');
  
    return `${title}${formattedJobs}`;
  };

  const FormatStudyData = ( studyData ) => {

    const title = '<h2> Formación Académica: </h2>\n\n';

    if (
      !studyData || 
      !studyData.higherEducation || // Verifica si higherEducation existe
      !Array.isArray(studyData.higherEducation) // Verifica si es un arreglo
    ) {
      return '<h2>No hay formación académica disponible</h2>';
    }
  
    const sortedStudies = studyData.higherEducation.slice().sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear));

    const formattedStudies = sortedStudies.map(study => {
      return `
        <strong>${study.otherCareer ? study.otherCareer.toUpperCase() : ''}${study.minor ? ' | ' + study.minor.toUpperCase() : ''}</strong>${study.otherInstitution} \n ${study.statusName || ''} \n ${study.entryYear} ~ ${study.graduationyear || 'Presente'}
      `.trim();
    }).join('\n\n ________ \n\n');
  
    return `${title}${formattedStudies}`;
  };

  const FormatPersonalData = ( PD ) => {
    const [day, month, year] = PD.personalInfo.birthDate.split('/').map(Number);
    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    return `
      <strong>${PD.personalInfo.firstName} ${PD.personalInfo.lastName} ${age ? `, ${age} años` : '' }</strong> \n\n ${PD.presentation || 'Sin Descripción'}
      
      Facebook: 
      ${PD.socialNetworks.facebookLink || ' - - -'} 
      Twitter: 
      ${PD.socialNetworks.twitterLink || ' - - -'} 
      LinkedIn: 
      ${PD.socialNetworks.linkedinLink ? `<a href='${PD.socialNetworks.linkedinLink}' target='_blank' rel='noopener noreferrer'>${PD.personalInfo.firstName} ${PD.personalInfo.lastName}</a>` : ' - - -'}
      
      🏠 ${PD.personalInfo.communeName} 
            Region ${PD.personalInfo.regionName} ${PD.personalInfo.residenceCountry ? `, ${PD.personalInfo.residenceCountry.description}` : ''}

      ☎️ ${PD.personalInfo.phoneNumbers[0].number || 'N/A'}

      ✉️ ${PD.personalInfo.emails.primaryEmail || 'N/A'}
    `.trim();
  };

  const ToggleCandidateSelection = (name) => {
    setSelectedCandidates(prev =>
      prev.includes(name) ? prev.filter(item => item !== name) : [...prev, name]
    );
  };

  const OpenRejectionPopup = () => {
    const rejectedCandidates = CVlist.filter(candidate => candidate.Rejected).map(candidate => candidate.name);
    setSelectedCandidates([...new Set([...SelectList2, ...rejectedCandidates])]);
    setShowPopup4(true);
  };
  

  const goto08 = () => { navigate('/P_Formulario') };  
  const goto10 = () => { navigate('/P_Referencias') };  

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });

      setFetchedData(data)
      setCompName(data.E_name)
      setFormID(data.FormID)
      localStorage.removeItem('fetched');
      localStorage['fetched'] = data;
  
      const data2 = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: data.FormID
      });
      setForm(data2);

      if (data.CVData){
        setCVlist(data.CVData.reverse())
        setselectlist(Array.isArray(data.SelectList) 
        ? data.SelectList               
        : data.SelectList               
          ? [data.SelectList]         
          : []);
        setselectlist2(Array.isArray(data.SelectList2) 
        ? data.SelectList2               
        : data.SelectList2               
          ? [data.SelectList2]         
          : []);
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);

  //############################################################################################################
  //############################################################################################################

  return (

    <div className='BackGround'>

      {/* Popup Envio Mail Individual */}
      <div className={ShowPopup ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup ? 'warningbox' : 'hidden'}>
          <h2>Confirma el Re-envio de formulario:</h2>
          <p style={{ alignItems: 'center', fontSize: '1.2rem' }}>
            se le enviara el formulario a: <strong>{SelectedName}</strong> <br /><br />
            con la dirección de correo: <strong>{selectedMail}</strong> <br /><br />
            O alternativamente, puedes copiar la URL para enviarla manualmente: <br /><br />
            <button 
              style={{ cursor: 'pointer' }} 
              onClick={() => copyToClipboard(`${baseUrl}?NM=${encodeURIComponent(SelectedName)}&PID=${encodeURIComponent(FormID)}`)}
            >
              <strong> <i className='fa-solid fa-copy'></i> &nbsp; Copiar la URL del formulario</strong>
            </button>
          </p>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {HandleMail(selectedMail, SelectedName, true); window.alert(` Formulario enviado al candidato ${SelectedName} `); setShowPopup(false) }}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formulario </button>
            <button onClick={() => setShowPopup(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Envio Mails Rechazo Masivo */}
      <div className={ShowPopup4? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup4(false)} >
        </div>
        <div className={ShowPopup4? 'warningbox' : 'hidden'}>
          <h2>Confirma a quienes se les enviara el Mensaje de rechazo</h2>
          <div style={{border:'none'}} className='notas'>
            {CVlist.filter(reply => SelectList.includes(reply.name))
                   .filter(reply => !SelectList2.includes(reply.name))
                   .filter(reply => !reply.Rejected)
                   .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', width: '-webkit-fill-available', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={!SelectedCandidates.includes(reply.name) ? 'start-button red' : 
                                                                        'start-button'}
                  onClick={() => {
                    ToggleCandidateSelection(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <input  type='checkbox' 
                        className='select-checkbox'
                        checked={!SelectedCandidates.includes(reply.name)}
                        onChange={() => {
                          ToggleCandidateSelection(reply.name);
                          console.log(SelectedCandidates)
                        }}></input>
              </div>
            ))}
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => HandleMailAll(false)}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Notificacion </button>
            <button onClick={() => setShowPopup4(false)}> Cancelar</button>
          </div>
        </div>
      </div>  

      {/* Main */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          📑 Ahora revisemos las respuestas de tus candidatos! 📑
        </h2>
      </div>

      <div className='MainBody'>

        {/* Lista de candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          {/* bottones superiores */}
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Candidatos : {SelectList.length}</h3> 
            <button
              className='sortbutton On'
              onClick={() => {
                if (SortType === 'score') {
                  setSortType('stars'); 
                } else if (SortType === 'stars') {
                  setSortType('name');  
                } else if (SortType === 'name') {
                  setSortType('chrono'); 
                } else {
                  setSortType('score');
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {SortType === 'score' && (
                <>
                  <i class="fa-solid fa-percent"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'stars' && (
                <>
                  <i class="fa-regular fa-star"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'name' && (
                <>
                  <i class='fa-solid fa-arrow-down-a-z'></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'chrono' && (
                <>
                  <i class="fa-regular fa-clock"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
            </button>
          </div>
          {/* Menu botones */}
          <div style={{ position:'sticky', 
                        border:'2px solid var(--main)',
                        borderRadius:'0vh 1vh 0vh 0vh',
                        backgroundColor:'var(--light)',
                        top: 0, zIndex: 4, 
                        padding:'1vh'}} >
            <button 
                    onClick={() => exportToExcel(CVlist)}>
              <i className='fa-solid fa-file-export'></i> &nbsp; Exporta las respuestas como XLSX
            </button>
            <button style={{marginTop:'1vh'}} 
                    onClick={OpenRejectionPopup}>
              <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Notifica a los no seleccionados 
            </button>
          </div>
          {/* lista de candidatos */}
          <div className='notas' style={{borderTop:'none', borderRadius:'0vh 0vh 1vh 1vh', marginBottom:'15.3vh'}}>
            {CVlist && CVlist.length > 0 ? (
              SortCVlist()
                .filter(reply => SelectList.includes(reply.name))
                .map((reply, index) => (
                <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <button
                    className={ reply.name ===  SelectedCVId ? 'start-button highlighted' : 
                                                reply.Warning === true ? 'start-button yellow' :
                                                reply.Warning === false ? 'start-button green' : 
                                                                                  'start-button'}
                    style={{  borderColor: reply.Rejected === true ? 'gray' : '',
                              backgroundColor: reply.Rejected ? 'var(--gray)' : ''
                     }}
                    onClick={() => {
                      setWait(true);
                      setScore(reply.stars2);
                      LookCV(reply.id);
                      setSelectedCVId(reply.name)
                      setSelectedName(reply.name)
                      setSelectedMail(reply.mail);
                      setSelectedSent(reply.FormSent)
                      setScreentype('preguntas')
                      handleformcheck(reply.name, reply.FormUpdated).then( form =>{
                        setTimeout(() => {
                          setWait(false);
                        }, 250);
                      })
                    }}
                  >
                    {reply.name}
                  </button>
                  <button className={ !reply.Nota2 ? 'middle-button' : 
                                                    JSON.parse(reply.Nota2) < 50 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.Nota2) < 70 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{  borderColor: reply.Rejected === true ? 'gray' : '', 
                                    borderRight:'none',
                                    backgroundColor: reply.Rejected ? 'var(--gray)' : '',
                                    maxWidth:'3vw' }}
                          onClick={() => {
                            setWait(true);
                            handleformcheck(reply.name, reply.FormUpdated).then(form => {
                            handleReneeopinion(form, reply.name).then(nota2 => {
                              saveCV(reply.name, nota2);
                              setTimeout(() => {
                                setWait(false);
                              }, 250);
                            })});
                          }}>
                    {reply.Nota2 ? JSON.parse(reply.Nota2) : ' - '}{'%'}
                  </button>
                  <button className={ !reply.stars2 ? 'middle-button' : 
                                                    JSON.parse(reply.stars2) < 3 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.stars2) < 5 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{  borderColor: reply.Rejected === true ? 'gray' : '', 
                                    borderRight:'none',
                                    backgroundColor: reply.Rejected ? 'var(--gray)' : '',
                                    maxWidth:'3vw' }}>
                    {reply.stars2 ? JSON.parse(reply.stars2) : ' - '}{'☆'}
                  </button>
                  <input  type='checkbox' 
                          className='select-checkbox'
                          checked={SelectList2 ? SelectList2.includes(reply.name) : false}
                          style={{  borderColor: reply.Rejected === true ? 'gray' : '',
                                    backgroundColor: reply.Rejected ? 'var(--gray)' : '',
                           }}
                          onChange={() => {
                            Handleselect2(reply.name);
                          }}></input>
                </div>
              ))
            ) : (
              <button>No hay candidatos</button>
            )}
          </div>
        </div>
        &nbsp;

        {/* Respuestas Formularios */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginBottom:'-0.2vh'}}>
          <div style={{display:'flex'}}>
            <h3 style={{display:'flex', alignItems: 'center'}} 
                className='boxtitle'>{SelectedCVId ? SelectedCVId+' | ' : ''} 
              <div  className={SelectedCVId? '' : 'hidden'}
                    style={{marginTop:'0'}}>
                <StarRating initialRating={Score} onRatingChange={handleRatingChange} />
              </div>
            </h3>   
            <button className='sortbutton On'
                    onClick={() => setScreentype(ScreenType === 'perfil' ? 'preguntas' : 'perfil')}
                    style = {{ textAlign:'left' , width:'20%', padding:'1vh', borderRadius:'1vh 3vh 0vh 0vh' }}
                    >
              {ScreenType === 'perfil' ? ' ir al formulario de preguntas ' : ' ir a Perfil del candidato'}
            </button>
          </div>
          <div  className= {SelectedName && ScreenType === 'preguntas' ? 'notas' : 'hidden'}
                style={{display:'flex',
                        flexDirection:'row',
                        borderRadius:'0vh 1vh 0vh 0vh',
                        height:'4vh',
                        overflow:'hidden',
                        gap:'1vh'}}>
                  <button onClick={AddPregunta}> 
                          <i className='fa-solid fa-plus'></i> &nbsp; Añade las Preguntas default
                  </button>
                  <button onClick={AddNota}> 
                          <i className='fa-solid fa-plus'></i> &nbsp; Añade un Bloque de Notas
                  </button>
                  <button className='Save' style={{width:'200%'}} onClick={ () => SaveForm ( Form , SelectedName) }>
                  <i className='fa-solid fa-floppy-disk'></i> &nbsp; Guardar cambios
                  </button>
          </div>
          <div className='notas' style={{ overflow: 'scroll', 
                                          overflowX:'hidden', 
                                          borderRadius:'0vh 0vh 1vh 1vh',
                                          borderTop: SelectedName && ScreenType === 'preguntas' ? 'none' : '',
                                          marginTop:'-4vh', 
                                          marginBottom: SelectedName && ScreenType === 'preguntas' ? '14.4vh' : '8vh'}}>
            <div>
            {Wait === true ? (
              <div className='box'>
                <img src={'/favicon.png'} alt='Profile' style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                <h2 style={{ color: 'var(--main)' }}>Espera un momento mientras Renee trabaja</h2>
                <p> Se estan procesando {remainingCandidates} candidatos, por favor espere un momento...</p>
                <div className='loader-container'>
                  <div className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>

            ) : Form.length > 0 && ScreenType === 'preguntas' ? (  
              <div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  {Form.map((block, index) => (
                    <div
                      key={index}
                      className='notas'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginRight: '1vh',
                        gap: '1vh',
                        overflow:'hidden',
                        backgroundColor: block.type === 'TextoNotas' ? 'var(--mid-light)' : ''
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div style={{ display: 'flex', alignItems: 'baseline', justifyContent:'space-between' }}>
                          <span
                            className='notas-title'
                            style={{ marginLeft: '1vh', fontWeight: 'bold', height:'8vh' }}
                          >
                            {block.title}
                          </span>
                          <button className={block.type === 'TextoNotas' ? 'box-button redb' : 'hidden'} 
                                  onClick={()=>DeletePreunta(index)}>
                          <i className="fa-solid fa-x"></i>
                          </button>
                        </div>
  
                        {block.type === 'Texto' ? (
                          <textarea
                            value={block.content}
                            className='notas'
                            readOnly
                            onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                            placeholder='Editar contenido'
                            rows='5'
                            style={{ height: '10vh', marginBottom: '1vh' }}
                          />
                        ) : block.type === 'TextoNotas' ? (
                          <textarea
                            value={block.content}
                            className='notas'
                            onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                            placeholder='Ingrese sus notas'
                            rows='5'
                            style={{ height: '10vh', marginBottom: '1vh' }}
                          />
                        ) : block.type === 'Alt Unica' ? (
                          <div className='alternatives-container'>
                            {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                              <label  key={optIndex} 
                                      className={ alternative.correct ? 'alternative-label green' : 'alternative-label' }>
                                <input
                                  type='radio'
                                  className='alternative-checkbox'
                                  disabled 
                                  checked={alternative.selected || false}
                                  onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                                />
                                {alternative.label}
                              </label>
                            ))}
                          </div>
                        ) : block.type === 'Alt Multiple' ? (
                          <div className='alternatives-container'>
                            {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                              <label  key={optIndex} 
                                      className={ alternative.correct ? 'alternative-label green' : 'alternative-label' }>
                                <input
                                  type='checkbox'
                                  className='alternative-checkbox'
                                  disabled 
                                  checked={alternative.selected || false}
                                  onChange={(e) => handleCheckboxChange(index, optIndex, e.target.checked)}
                                />
                                {alternative.label}
                              </label>
                            ))}
                          </div>
                        ) : null}
                        
                      </div>      
                    </div>
                  ))}
                </div>
              </div>
            ) : ScreenType === 'perfil' ? (
              <div style={{display:'flex', gap:'1vh'}}>
                <div className='boxfit' style={{ maxWidth: '20vw' }}>
                  <div className='notas'>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                      <img
                        src={CV && CV.personalInfo.picture ? CV.personalInfo.picture : '/default.png'}
                        alt='Profile'
                        style={{
                          width: '150px',
                          height: '150px',
                          objectFit: 'cover',
                          borderRadius: '50%',
                          position:'stick'
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: CV ? FormatPersonalData(CV) : '' }}
                        style={{
                          whiteSpace: 'pre-wrap',
                          textAlign:'start'
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className= { ScreenType === 'perfil' ? 'notas' : 'hidden'}
                  dangerouslySetInnerHTML={{ __html: CV ? FormatJobData(CV.workExperience) 
                                                        + '\n\n\n\n'
                                                        + FormatStudyData(CV.studies) : '' }}
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflowY: 'auto',
                    textAlign: 'left'
                  }}
                />
              </div>
            ) : SelectedSent === true ? ( 
              <div style={{display:'flex', flexDirection:'column',alignItems:'center', fontSize: '1.2rem'}}>
                <h2>Sin respuesta disponible</h2>
                <p> Opcionalmente puedes reenviar el formulario al candidato si lo consideras apropiado</p>
                <p> Considera que este corresponde al mismo mensaje enviado en la pantalla de creacion del Formulario</p>
                <button onClick={() => setShowPopup(true)} style={{width:'30%'}}> Enviar nuevamente el formulario </button>
                <p> O alternativamente puedes</p>
                <button className='redb' onClick={() => Handleselect(SelectedName)} style={{width:'30%'}}> Remover al candidato de esta etapa </button>
              </div>
            ) : (
              <div style={{display:'flex', flexDirection:'column',alignItems:'center', fontSize: '1.2rem'}}> 
                <h2>Sin respuesta disponible</h2>
                <p>Te recordamos que aún no se ha enviado el correo del formulario a este candidato.</p>
                <p>Puedes enviarlo desde la pantalla anterior de formulario o directamente aquí utilizando el siguiente botón:</p>
                <button onClick={() => setShowPopup(true)} style={{width: '30%'}}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar el formulario</button>
                <p>O, alternativamente, puedes:</p>
                <button className='redb' onClick={() => Handleselect(SelectedName)} style={{width: '30%'}}>
                <i className="fa-solid fa-x"></i> &nbsp; Remover al candidato de esta etapa
                </button>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
      
      <div className='bottom'>
        <button onClick={goto08}>volver</button>
        <button className='Save last-button' onClick={() => { SaveForm(Form , SelectedName) ; goto10()}}>Guardar y Continuar</button>
      </div>
    </div>
  );
}
