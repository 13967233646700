
//=============================================================================
// Pantalla Login
//=============================================================================

import './css/App.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LOGCALL from './LOGCALL';

export default function Login({ setToken }) {
  
  const navigate = useNavigate();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
	const [U_name , setU_name]    = useState('');
  const [U_city , setU_city]    = useState('');
  const [U_mail , setU_mail]    = useState('');
  const [U_phone, setU_phone]   = useState('');
  const [U_emp  , setU_emp]     = useState('');

  const [Popup1, setPopup1]     = useState(false);
  const [Popup2, setPopup2]     = useState(false);
  const [error, setError]       = useState(''); 
  let count                     = 0;
  

  async function HandleLoggin (e) {
    e.preventDefault();
    setError('');

    const result = await LOGCALL({
      apicall: 'US_LOG',
      username,
      password
    });

    if (result.token) {
      console.log(result);
      sessionStorage.setItem('Grupo', result.empresa);
      sessionStorage.setItem('token', result.token);
      sessionStorage.setItem('mail', username);
      sessionStorage.setItem('rol', result.rol);
      sessionStorage.setItem('environment', 'Production');
      sessionStorage.setItem('test', 'Production');
      window.location.reload();
    } else {
      setError(result.error || ' '); 
    }
  };

  async function Handleout (e) {
    e.preventDefault();
    setError('');
    
    setPopup1(false);
    setPopup2(false);
  };

  async function MailContacto (e) {
    e.preventDefault();
    setError('');

    await LOGCALL({
      apicall: 'US_CON',
      U_name : U_name,
      U_city : U_city,
      U_mail : U_mail,
      U_phone : U_phone,
      U_emp : U_emp
    });
    alert('Gracias por contactarnos, pronto le responderemos !');
  };

  async function MailRecuperar (e) {
    e.preventDefault();

    const result = await LOGCALL({
      apicall: 'US_PASS',
      U_mail : U_mail
    });
    if (result.error){
      alert('Mail no registrado');
    } else {
      alert('Pronto recibiras una clave temporal a tu dirección de correo');
    }
  };

  const GotoMS = () => {
    if (count === 5){
      navigate('/MS'); 
    } else {
      count = count+1;
    }
	};

  //############################################################################################################
  //############################################################################################################

  return(

    <main className='loggin'>

      {/* Pop-up Mail contacto */}
      <div className={Popup1 ? 'popupBG' : 'hidden'}>
        <div className='outside'  onClick={Handleout} >
        </div>
        <div className='popup'> 
          <h2>Nos pondremos en contacto contigo para que te puedas unir a Reneé </h2>
          <button className='out redb' onClick={Handleout}><i className="fa-solid fa-x"></i></button>
          <div>
            <form onSubmit={MailContacto} className='popform'>
            <label><strong>Nombre</strong>
            <input  type='text'
                    id='Nombre'
                    required
                    placeholder= {'Nombre'}
                    onChange={e => setU_name(e.target.value)}/>
            </label>

            <label><strong>Teléfono</strong>
            <input  type='number'
                    id='Telefono'
                    required
                    placeholder= {'Telefono'}
                    onChange={e => setU_phone(e.target.value)}/>
            </label>

            <label><strong>Ciudad</strong>
            <input  type='text'
                    id='Ciudad'
                    required
                    placeholder= {'Ciudad'}
                    onChange={e => setU_city(e.target.value)}/>
            </label>

            <label><strong>Empresa</strong>
            <input  type='text'
                    id='Empresa'
                    required
                    placeholder= {'Empresa'}
                    onChange={e => setU_emp(e.target.value)}/>
            </label>

            <label><strong>Correo</strong>
            <input  type='email'
                    id='Correo'
                    required
                    placeholder= {'Correo'}
                    onChange={e => setU_mail(e.target.value)}/>
            </label>

            <button style={{width:'50%',marginBottom:'0px'}} type='submit'> enviar tus datos </button>
            
            </form>
          </div>
        </div>
      </div>

      {/* Pop-up Recuperacion contraseña */}
      <div className={Popup2 ? 'popupBG' : 'hidden'}>
        <div className='outside'  onClick={Handleout} >
        </div>
        <div className='popup'>
          <button 
            className='out redb' 
            onClick={Handleout}>
            <i className="fa-solid fa-x"></i>
          </button> 
          <h2> Has olvidado tu clave?  </h2>
          <h3> Ingresa el correo en el cual quieres recibir tu clave temporal </h3>
          <div>
            <form  onSubmit={MailRecuperar} className='popform'>

            <label><strong>Correo</strong>
            <input  type='email'
                    id='Mail'
                    required
                    placeholder= {'Correo'}
                    onChange={e => setU_mail(e.target.value)}/>
            </label>
            <button type='submit' style={{width:'50%',marginBottom:'0px'}}> enviar tus datos </button>
            
            </form>
          </div>
        </div>
      </div>


      {/* Cuerpo Login */}
      {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
      {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
      <img 
        src='/favicon.png' 
        alt='favicon' 
        style={{ width:'20vh', height:'20vh' }}
        className='logo' 
        onClick={GotoMS}
      />
      <div  className='MainBody'
            style={{height:'75%', width:'40%', boxShadow:'2vh 2vh 0vh 0px rgba(0, 0, 0, 0.44)',borderRadius:'10px'}}>
        <div className='boxlog'>
          <h1>¡Bienvenido a Renee!</h1>
          <h3>Por favor ingresa tus datos</h3>
          <form onSubmit={HandleLoggin}>
        
            <input type='text' placeholder='Correo Electronico' style={{height:'5vh', fontSize:'1.2em', textAlign:'center'}}
            onChange={e => setUserName(e.target.value)} />
        
            <input type='password' placeholder='Clave' style={{height:'5vh', fontSize:'1.2em', textAlign:'center'}}
            onChange={e => setPassword(e.target.value)} />
        
            <button type='submit' style={{height:'6vh', width:'60%', marginLeft:'20%'}}>
              INICIAR SESION
            </button>
              
            {error && <div className='error'>{error}</div>}
          </form>
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', marginTop:'20vh'}}>
            <h4 onClick={ () => setPopup1(true) }> Quiero Unirme a Reneé </h4>
            <div></div>
            <h4 onClick={ () => setPopup2(true) }> Olvide mi clave </h4>
          </div>
        </div>
      </div>
    </main>
  );
}

