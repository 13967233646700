
//=============================================================================
// Pantalla Recuperación de Respuestas Pre-Screening
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import LOGCALL from './LOGCALL';
import StarRating from '../components/star';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default function Referencias() {
  const navigate = useNavigate();

  const [Score, setScore] = useState('0');
  // const [FormID, setFormID] = useState(''); 
  // const [CompName, setCompName] = useState('');
  const [SortType, setSortType] = useState('chrono');
  const [WHOreply, setWHOreply] = useState('');
  const [SelectedRUT, setselectedRUT] = useState('');
  // const [FetchedData, setFetchedData] = useState(''); 
  const [SelectedName, setSelectedName] = useState('');
  const [SelectedNameR, setSelectedNameR] = useState('');
  const [SelectedMailR, setSelectedMailR] = useState('');
  const [ScreenType, setScreentype] = useState('perfil');
  const [SelectedCategories, setSelectedCategories] = useState('');
  const [Form, setForm] = useState([]); 
  const [CV, setCV] = useState(null);
  const [CVlist, setCVlist] = useState([]);
  const [SelectList2, setselectlist2] = useState([]);
  const [SelectList3, setselectlist3] = useState([]);
  const [Wait, setWait] = useState(false);
  const [ShowPopup, setShowPopup] = useState(false);
  const [ShowPopup2, setShowPopup2] = useState(false);
  const [ShowPopup3, setShowPopup3] = useState(false);
  const [ShowPopup4, setShowPopup4] = useState(false);
  const [SelectedCVId, setSelectedCVId] = useState(null);
  const [countryCode, setCountryCode] = React.useState("+56");
  const [referenceName, setReferenceName] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [referenceEmail, setReferenceEmail] = useState('');

  
  async function ReferentData(CVlist, SelectList2) {
    const filteredCVlist = CVlist.filter(candidate => SelectList2.includes(candidate.name));
    const candidateData = [];
    let headers = ['Nombre Candidato', 'Email Candidato', 'RUT Candidato', 'Nombre Referente', 'Email Referente'];
    let maxColumnsReference = null;
  
    try {
      for (const candidate of filteredCVlist) {
        for (const reference of candidate.reference) {
          try {
            const reply = await handleformcheck(candidate.name, reference.name);
            if (reply.response !== '') {
              maxColumnsReference = { reference, reply };
            }
          } catch (error) {
            console.warn(`Error al obtener formulario para referencia ${reference.name}:`, error);
            continue;
          }
        }
      }

      if (maxColumnsReference) {
        maxColumnsReference.reply.forEach((item, idx) => {
          headers.push(item.title || `Pregunta ${idx + 1}`);
        });
  
        for (const candidate of filteredCVlist) {
          for (const reference of candidate.reference) {
            try {
              const formData = await handleformcheck(candidate.name, reference.name);
              // if (!Array.isArray(formData)) {
              //   continue;
              // }
  
              const formattedData = {
                'Nombre Candidato': candidate.name,
                'Email Candidato': candidate.mail || ' - - - ',
                'RUT Candidato': candidate.rut || ' - - - ',
                'Nombre Referente': reference.name,
                'Email Referente': reference.email || ' - - - '
              };
  
              headers.slice(5).forEach((header, idx) => {
                const question = formData[idx];
                formattedData[header] =
                  question &&
                  (question.type === 'Alt Multiple' || question.type === 'Alt Unica') &&
                  question.alternatives
                    ? question.alternatives.filter(alt => alt.selected).map(alt => alt.label).join(', ') || ' - - - '
                    : question?.content || ' - - - ';
              });
  
              candidateData.push(formattedData);

            } catch (error) {
              
              console.warn(`Error al obtener formulario para referencia ${reference.name}:`, error);
              continue;
            }
          }
        }
      }
    } catch (error) {
      console.error('Error general en ReferentData:', error);
    }
  
    const formattedDataForExcel = [headers];
    candidateData.forEach(dataRow => {
      const row = headers.map(header => dataRow[header] || ' - - - ');
      formattedDataForExcel.push(row);
    });
  
    return formattedDataForExcel;
  }
  
  async function exportToExcel(CVlist) {
    try {
      const compiledData = await ReferentData(CVlist, SelectList2);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Referencias');
  
      const headers = compiledData[0];
      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell(cell => {
        cell.font = { name: 'Arial', size: 12, bold: true, color: { argb: 'FFFFFFFF' } };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
  
      for (let i = 1; i < compiledData.length; i++) {
        const row = worksheet.addRow(compiledData[i]);
        row.eachCell(cell => {
          cell.alignment = { vertical: 'top' };
        });
      }
  
      headers.forEach((header, index) => {
        worksheet.getColumn(index + 1).width = 40;
      });
  
      const buffer = await workbook.xlsx.writeBuffer();
      const fileName = `Referencias_${sessionStorage.getItem('name')}.xlsx`;
      saveAs(new Blob([buffer]), fileName);
    } catch (error) {
      console.error('Error general en exportToExcel:', error);
    }
  }
  
  


  async function Handleselect3(name) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select3: !cv.select3} 
          : cv
      )
    );
  
    const updatedSelectList3 = SelectList3.includes(name)
      ? SelectList3.filter(id => id !== name)
      : [...SelectList3, name];
  
    setselectlist3(updatedSelectList3);
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList3: updatedSelectList3
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function handleformcheck(NameC, NameR) {

    setWait(true)
  
    try {
      const resp = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: NameR+'_'+NameC
      });
      setForm(resp);
      return resp;

    } catch (error) {
      console.error('Error during fetch:', error);
      return [];
    }

  };

  async function deleteReference() {
    try {
      const selectedCV = CVlist.find(cv => cv.name === SelectedName);
  
      if (!selectedCV) {
        console.error('CV no encontrado para el nombre seleccionado.');
        return;
      }
  
      const updatedReferences = selectedCV.reference?.filter(ref => ref.email !== SelectedMailR) || [];
  
      const updatedCVlist = CVlist.map(cv =>
        cv.name === SelectedName
          ? { ...cv, reference: updatedReferences }
          : cv
      );
  
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: {
          name: SelectedName,
          reference: updatedReferences
        }
      });
  
      setCVlist(updatedCVlist);
      alert("Referencia eliminada correctamente.");
    } catch (error) {
      console.error('Error durante la eliminación de la referencia:', error);
    }
  };

  async function handleSendReference() {
    if (referenceName && referenceEmail) {
      
      try {
        await LOGCALL({
          apicall: 'FM_REF',
          code: sessionStorage.getItem('name'),
          Mail: referenceEmail,
          NameR: referenceName,
          NameC: SelectedName,
          P_name: sessionStorage.getItem('token'),
          P_mail: sessionStorage.getItem('mail')
        });
        alert("Mensaje enviado a " + referenceEmail);
      } catch (error) {
        console.error('Error during fetch:', error);
      }

      setReferenceName('');
      setReferenceEmail('');
      setShowPopup(false);
    }
  };

  async function handleAddReference() {
    if (referenceName && referenceEmail) {
  
      try {
        const selectedCV = CVlist.find(cv => cv.name === SelectedName);
  
        if (selectedCV?.reference?.some(ref => ref.email === referenceEmail)) {
          setReferenceName('');
          setReferenceEmail('');
          setShowPopup(false);
          return;
        }
  
        const newReference = { name: referenceName, phone:countryCode+referenceNumber,  email: referenceEmail };
        const updatedCVlist = CVlist.map(cv =>
          cv.name === SelectedName
            ? {
                ...cv,
                reference: Array.isArray(cv.reference)
                  ? [...cv.reference, newReference]
                  : [newReference]
              }
            : cv
        );
  
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.getItem('currentsave'),
          U_emp: sessionStorage.getItem('Grupo'),
          CVData: {
            name: SelectedName,
            reference: updatedCVlist.find(cv => cv.name === SelectedName)?.reference
          }
        });
        setCVlist(updatedCVlist);
        setReferenceName('');
        setReferenceEmail('');
        setShowPopup(false);
      } catch (error) {
        console.error('Error durante la actualización:', error);
      }
    }
  };
  
  async function saveWarning( Namedata , warning, Rut ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('currentsave'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: Namedata, Warning: warning, RUT: Rut}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

    const data2 = await APICALL({
      apicall: 'PD_fetch',
      code: sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo')
    });

    if (data2.CVData){
      setCVlist(data2.CVData);
    }
  };

  async function WhoCall(type , name, rut) {
    setWait(true)
    try {
      const data = await APICALL({
        apicall: 'WH_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        type: type,
        name: name,
        rut : rut
      });
      if (data.data.client) {
        if (data.data.client === 'Sandbox'){
          console.log('caso sandbox')
          setWHOreply(data.data)
          if (data.data.laboral.causas_totales || data.data.criminal.data?.length > 0){
            saveWarning( name , true, rut );
          } else {
            saveWarning( name , false, rut );
          }
        } else {
          console.log('caso produccion')
          setWHOreply(data)
          if (data.laboral.causas_totales || data.criminal.data?.length > 0){
            saveWarning( name , true, rut );
          } else {
            saveWarning( name , false, rut );
          }
        }
      } else {
        console.log('caso excepcion')
          setWHOreply(data.data)
      }

      setShowPopup2(false)
      setShowPopup3(true)
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

    setTimeout(() => {
      setWait(false);
    }, 500);
  };

  async function LookCV( data ) {

    const apiCallType = data.includes('CVE_') ? 'CV_fetch' : 'TB_fetch';


    try {
      const reply = await APICALL({
        apicall: apiCallType,
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        id_code: data
      });

      function replaceNullStrings(obj) {
        for (const key in obj) {
          if (obj[key] === 'Null' || obj[key] === null) {
            obj[key] = ' - - -';
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceNullStrings(obj[key]);
          }
        }
        return obj;
      }
      
      let profile 

      if (apiCallType === 'CV_fetch'){
        profile = replaceNullStrings(reply.data)
      } else {
        profile = reply.data
      }

      const { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments } = profile;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments };

      setCV(cv);
  
    } catch (error) {
      console.error('Error during fetch:', error);
      setCV(null)
    }

  };

  async function handleRatingChange (newRating) {
    setScore(newRating);
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === SelectedCVId
          ? { ...cv, stars3: newRating} 
          : cv
      )
    );
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: SelectedCVId, stars3: newRating}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

  };

  async function RutCheck (Rut) {

    function dgv(T) {
      var M = 0;
      var S = 0;
      var multipliers = [2, 3, 4, 5, 6, 7];
      
      while (T > 0) {
        var digit = T % 10;
        S += digit * multipliers[M % 6];
        M++;
        T = Math.floor(T / 10);
      }
      
      var remainder = 11 - (S % 11);
      
      if (remainder === 11) return 0;
      if (remainder === 10) return 'K';
      return remainder;
    }
    // eslint-disable-next-line
    const cleanRut = Rut.replace(/[\.\-]/g, '');
    let verif = (cleanRut.slice(-1));
    if (verif === 'k'){ verif = 'K'};

    if (String(dgv(cleanRut.slice(0,-1))) === verif){
      WhoCall("p&l", SelectedName, Rut)
    } else {
      window.alert('RUT invalido.')
    }
  };





  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('URL copiada al portapapeles!');
      })
      .catch(err => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };

  const ToggleCategorySelection = (category) => {
    setSelectedCategories((prevCategories) => {
      const categoriesArray = prevCategories ? prevCategories.split('&') : [];
  
      if (categoriesArray.includes(category)) {
        const updatedArray = categoriesArray.filter((item) => item !== category);
        return updatedArray.sort().reverse().join('&');
      } else {
        return [...categoriesArray, category].sort().reverse().join('&');
      }
    });
  };

  const handleInputChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };

  const FormatJobData = (jobData) => {
    const title = '<h2>Experiencia Laboral:</h2>\n\n';
  
    const sortedJobs = jobData.slice().sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));
  
    const formattedJobs = sortedJobs.map(job => {
      const achievements = job.achievements.includes('\n?') 
        ? job.achievements.split('\n?').filter(line => line.trim() !== '') 
        : job.achievements.split('\n').filter(line => line.trim() !== '');
  
      const formattedAchievements = achievements.map(achievement => `🞄 ${achievement}`).join('<br>');
  
      return `
        <strong>${job.jobPosition ? job.jobPosition.toUpperCase() : ''}</strong> ${job.companyName} || ${job.companyActivity ? job.companyActivity.description : ''} <br> ${job.fromDate} - ${job.toDate || 'Presente'}
        <br> ${formattedAchievements}
      `.trim();
    }).join('\n\n ________ \n\n');
  
    return `${title}${formattedJobs}`;
  };

  const AddNota = () => {
    const newNotes = [
      { title: 'Observacion Adicional', content: '', type: 'TextoNotas' }
    ];
    setForm((prevForm) => [...prevForm, ...newNotes]);
  };

  const FormatStudyData = ( studyData ) => {

    const title = '<h2> Formación Académica: </h2>\n\n';

    if (
      !studyData || 
      !studyData.higherEducation || // Verifica si higherEducation existe
      !Array.isArray(studyData.higherEducation) // Verifica si es un arreglo
    ) {
      return '<h2>No hay formación académica disponible</h2>';
    }
  
    const sortedStudies = studyData.higherEducation.slice().sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear));

    const formattedStudies = sortedStudies.map(study => {
      return `
        <strong>${study.otherCareer ? study.otherCareer.toUpperCase() : ''}${study.minor ? ' | ' + study.minor.toUpperCase() : ''}</strong>${study.otherInstitution} \n ${study.statusName || ''} \n ${study.entryYear} ~ ${study.graduationyear || 'Presente'}
      `.trim();
    }).join('\n\n ________ \n\n');
  
    return `${title}${formattedStudies}`;
  };

  const FormatPersonalData = ( PD ) => {
    const [day, month, year] = PD.personalInfo.birthDate.split('/').map(Number);
    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    return `
      <strong>${PD.personalInfo.firstName} ${PD.personalInfo.lastName} ${age ? `, ${age} años` : '' }</strong> \n\n ${PD.presentation || 'Sin Descripción'}
      
      Facebook: 
      ${PD.socialNetworks.facebookLink || ' - - -'} 
      Twitter: 
      ${PD.socialNetworks.twitterLink || ' - - -'} 
      LinkedIn: 
      ${PD.socialNetworks.linkedinLink ? `<a href='${PD.socialNetworks.linkedinLink}' target='_blank' rel='noopener noreferrer'>${PD.personalInfo.firstName} ${PD.personalInfo.lastName}</a>` : ' - - -'}
      
      🏠 ${PD.personalInfo.communeName} 
            Region ${PD.personalInfo.regionName} ${PD.personalInfo.residenceCountry ? `, ${PD.personalInfo.residenceCountry.description}` : ''}

      ☎️ ${PD.personalInfo.phoneNumbers[0].number || 'N/A'}

      ✉️ ${PD.personalInfo.emails.primaryEmail || 'N/A'}
    `.trim();
  };

  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA; 
      } else if (SortType === 'stars') {
        const starsA = a.stars ? a.stars : 0;
        const starsB = b.stars ? b.stars : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });

    if (SortType === 'chrono') {
      return CVlist;
    } else {
      return sortedList;
    }
  };

  const handlesubbmit = async () => {
    await APICALL({
      apicall: 'FM_submmit',
      FormID: SelectedNameR ,
      Name: SelectedName,
      Formreply: Form
    });
  };

  const DeletePreunta = (indexToRemove) => {
    const updatedForm = Form.filter((_, index) => index !== indexToRemove);
    setForm(updatedForm);
  };

  const goto09 = () => { navigate('/P_Respuestas') };  
  // const goto11 = () => { navigate('/P_WIP2') };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });

      if (data.CVData){
        setCVlist(data.CVData.reverse())
        setselectlist2(Array.isArray(data.SelectList2) 
        ? data.SelectList2               
        : data.SelectList2               
          ? [data.SelectList2]         
          : []);
        setselectlist3(Array.isArray(data.SelectList3) 
        ? data.SelectList3               
        : data.SelectList3               
          ? [data.SelectList3]         
          : []);
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);

  //############################################################################################################
  //############################################################################################################

  return (

    <div className='BackGround'>

      {/* Popup Agregar Referencia */}
      <div className={ShowPopup ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup ? 'warningbox' : 'hidden'}>
          <h2>Agrega una nueva referencia para {SelectedName}</h2>
          <p style={{ alignItems: 'center', fontSize: '1.2rem' }}>
            Este se Agregara a la lista de referentes asociados al candidato {SelectedName}
          </p>
          <div 
            className='form-container'
            style={{ display: 'flex', flexDirection: 'column', gap: '1vh', padding: '1vh', width: '-webkit-fill-available' }}>
            <label>Nombre de la referencia: 
              <input
                type="text"
                style={{paddingLeft:'1vh'}}
                placeholder="Nombre"
                value={referenceName}
                onChange={(e) => setReferenceName(e.target.value)}
              />
            </label>
            <label>Teléfono de la referencia:
              <div style={{ display: "flex", alignItems: "center" }}>
                <select
                  style={{ marginRight: "0.5vh", padding: "0.5vh", width: "25%" }}
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option value="+56">Chile (+56)</option>
                  <option value="+51">Perú (+51)</option>
                  <option value="+54">Argentina (+54)</option>
                  <option value="+52">México (+52)</option>
                  <option value="+57">Colombia (+57)</option>
                  <option value=" "  >Otro Pais </option>
                </select>
                
                <input
                  type="number"
                  style={{ paddingLeft: "1vh", flex: 1 }}
                  placeholder="Número"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
              </div>
            </label>
            <label>Correo de la referencia: 
              <input
                type="email"
                style={{paddingLeft:'1vh'}}
                placeholder="Correo electrónico"
                value={referenceEmail}
                onChange={(e) => setReferenceEmail(e.target.value)}
              />
            </label>
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={handleAddReference}> <i className='fa-regular fa-user'></i> &nbsp; Agregar Referencia</button>
            <button onClick={() => setShowPopup(false)}>Cancelar</button>
          </div>
        </div>
      </div> 

      {/* Popup Alertas Legales Confirmacion */}
      <div className={ShowPopup2 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup2(false)} >
        </div>
        <div className={ShowPopup2 ? 'warningbox' : 'hidden'}>
        <h2>Confirmación de Check de Alertas Legales</h2>
        <p style={{ alignItems: 'center', fontSize: '1.2rem', overflow:'scroll' }}>
          <strong>Nombre: {SelectedName}</strong><br /><br />
          <strong>RUT: {SelectedRUT}</strong><br /><br />
          Ten en cuenta que este check de alertas legales puede realizarse <strong>únicamente una vez por candidato</strong>.<br/>
          Cualquier consulta posterior mostrará únicamente los resultados obtenidos en la primera consulta.<br/>
          Asegúrate de seleccionar las categorías que deseas consultar con cuidado.
        </p>


          <div style={{ display: 'flex', flexDirection: 'row', alignItems:'flex-end', gap: '1vh' }}>
          Seleccione la categoria a consultar:  <br /><br />

          {/* <div style={{ display: 'flex'}}>
            <button className='start-button'
                    onClick={() => toggleCategorySelection('c')}>Civil</button>
            <input
              type='checkbox'
              className='select-checkbox'
              checked={selectedCategories.includes('c')}
              onChange={() => toggleCategorySelection('c')}
            />
          </div> */}

          <div style={{ display: 'flex'}}>
            <button className='start-button'
                    onClick={() => ToggleCategorySelection('l')}>laboral</button>
            <input
              type='checkbox'
              className='select-checkbox'
              checked={SelectedCategories.includes('l')}
              onChange={() => ToggleCategorySelection('l')}
            />
          </div>

          <div style={{ display: 'flex'}}>
            <button className='start-button'
                    onClick={() => ToggleCategorySelection('p')}>Penal</button>
            <input
              type='checkbox'
              className='select-checkbox'
              checked={SelectedCategories.includes('p')}
              onChange={() => ToggleCategorySelection('p')}
            />
          </div>
    
        </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {WhoCall(SelectedCategories, SelectedName, SelectedRUT)}}>Confirmar</button>
            <button onClick={() => setShowPopup2(false)}>Cancelar</button>
          </div>
        </div>
      </div> 

      {/* Popup Alertas Legales Resultados */}
      <div className={ShowPopup3 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup3(false)} >
        </div>
        <div style={{width:'80%', height:'80%', left:'10%',top:'10%', overflow:'scroll'}} className={ShowPopup3 ? 'warningbox' : 'hidden'}>
          {WHOreply.error ? (
            <div style={{display:'flex', width:'-webkit-fill-available', justifyContent:'space-evenly'}}>
              {WHOreply.error}
            </div>
            ) : (
            <div>
              <h2>Detalles de la Respuesta</h2>

              <div style={{display:'flex', width:'-webkit-fill-available', justifyContent:'space-evenly'}}>
                {/* {WHOreply.civil && WHOreply.civil.data && (
                  <div>
                    <h3>Categoría Civil</h3>
                    <p><strong>Status:</strong> {WHOreply.civil.status}</p>
                    <p><strong>Causas Totales:</strong> {WHOreply.civil.defendant_records}</p>
    
                      {WHOreply.civil.data.map((record, index) => (
                        <div key={index} className='warninblock' >
                          <p><strong>Riesgo:</strong> {record.risk}</p>
                          <p><strong>Fecha:</strong> {record.date}</p>
                          <p><strong>Contraparte:</strong> {record.complainant}</p>
                          <p><strong>Nombre Contraparte:</strong> {record.complainant_name}</p>
                          <p><strong>Proceso:</strong> {record.process}</p>
                          <p><strong>RUT Contraparte:</strong> {record.complainant_dni}</p>
                          <p><strong>RIT:</strong> {record.rit}</p>
                          <p><strong>Tribunal:</strong> {record.court}</p>
                        </div>
                      ))}
                    </div>
                )} */}
    
                {WHOreply.laboral && WHOreply.laboral.data && (
                  <div>
                    <h3>Categoría Laboral</h3>
                    <p><strong>Status:</strong> {WHOreply.laboral.status}</p>
                    {/* <p><strong>Tipo Persona:</strong> {WHOreply.laboral.tipo_persona}</p> */}
                    {/* <p><strong>Causas Demandante:</strong> {WHOreply.laboral.causas_demandante}</p> */}
                    {/* <p><strong>Causas Participante:</strong> {WHOreply.laboral.causas_participante}</p> */}
                    <p><strong>Causas Totales:</strong> {WHOreply.laboral.causas_totales}</p>
    
                    {WHOreply.laboral.data.map((record, index) => (
                      <div key={index} className='warninblock' >
                        {/* <p><strong>Año:</strong> {record.ano}</p> */}
                        <p><strong>Fecha:</strong> {record.fecha_ingreso}</p>
                        <p><strong>Nombre Contraparte:</strong> {record.nombre_contraparte}</p>
                        <p><strong>Participación:</strong> {record.participacion}</p>
                        <p><strong>RIT:</strong> {record.rit}</p>
                        <p><strong>RUT Contraparte:</strong> {record.rut_contraparte}</p>
                        <p><strong>Tipo:</strong> {record.tipo}</p>
                        <p><strong>Tribunal:</strong> {record.tribunal}</p>
                      </div>
                    ))}
                  </div>
                )}
    
                {WHOreply.criminal && WHOreply.criminal.data && (
                    <div>
                      <h3>Categoría Penal</h3>
                      <p><strong>Status:</strong> {WHOreply.criminal.status}</p>
                      <p><strong>Causas Totales:</strong> {WHOreply.criminal.data.length}</p>
    
                      {WHOreply.criminal.data.map((record, index) => (
                        <div key={index} className='warninblock' >
                          <p><strong>Riesgo:</strong> {record.risk}</p>
                          <p><strong>Fecha:</strong> {record.date}</p>
                          <p><strong>Crimen:</strong> {record.crime}</p>
                          {/* <p><strong>Año:</strong> {record.year}</p> */}
                          <p><strong>RUC:</strong> {record.ruc}</p>
                          <p><strong>Tribunal:</strong> {record.court}</p>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          )}
          <div style={{ display: 'flex', position:'sticky', bottom:'0', gap: '1vh', padding: '1vh', width: '50%' }}>
            <button onClick={() => setShowPopup3(false)}>Volver</button>
          </div>
        </div>
      </div>

      {/* Popup Mensaje Referencia */}
      <div className={ShowPopup4 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup4(false)} >
        </div>
        <div className={ShowPopup4 ? 'warningbox' : 'hidden'}>
          <h2>Confirma el envio del formulario:</h2>
            <p style={{ alignItems: 'center', fontSize: '1.2rem' }}>
              Se le enviara el formulario a: <strong>{referenceName}</strong> <br />
              Con la dirección de correo: <strong>{referenceEmail}</strong> <br /><br />
              Alternativamente, puedes copiar la URL para enviarla manualmente: <br /><br />
              <button 
                style={{ cursor: 'pointer' }} 
                onClick={() => copyToClipboard(`https://forms.renee.cl/?NR=${encodeURIComponent(referenceName)}&NC=${encodeURIComponent(SelectedName)}`)}
              >
                <strong> <i className='fa-solid fa-copy'></i> &nbsp; Copiar la URL del formulario</strong>
              </button> <br />
              O alternativamente puedes: <br /><br />
              <button 
                style={{ cursor: 'pointer' }} 
                onClick={() => window.open(`https://forms.renee.cl/?NR=${encodeURIComponent(referenceName)}&NC=${encodeURIComponent(SelectedName)}`)}
              >
                <strong> <i className="fa-solid fa-file-signature"></i> &nbsp; Rellenar los datos manualmente</strong>
              </button>
            </p>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={handleSendReference}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formulario de referencias </button>
            <button onClick={() => setShowPopup4(false)}>Cancelar</button>
          </div>
        </div>
      </div> 

      {/* Main */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          🔎 Que tal si ahora Revisemos las referencias de nuestros candidatos! 🔎
        </h2>
      </div>

      <div className='MainBody'>

        {/* Lista de candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          {/* Menu superior */}
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Candidatos : {SelectList2.length}</h3> 
            <button
              className='sortbutton On'
              onClick={() => {
                if (SortType === 'stars') {
                  setSortType('name');  
                } else if (SortType === 'name') {
                  setSortType('chrono'); 
                } else {
                  setSortType('stars');
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {SortType === 'score' && (
                <>
                  <i className="fa-solid fa-percent"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'stars' && (
                <>
                  <i className="fa-regular fa-star"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'name' && (
                <>
                  <i className='fa-solid fa-arrow-down-a-z'></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'chrono' && (
                <>
                  <i className="fa-regular fa-clock"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
            </button>
          </div>
          {/* Menu botones */}
          <div style={{ position:'sticky', 
              border:'2px solid var(--main)',
              borderRadius:'0vh 1vh 0vh 0vh',
              backgroundColor:'var(--light)',
              top: 0, zIndex: 4, 
              padding:'1vh'}} >
            <button 
                    onClick={() => exportToExcel(CVlist)}
                    >
              <i className='fa-solid fa-file-export'></i> &nbsp; Exporta las respuestas como XLSX
            </button>
          </div>
          {/* Lista de candidatos */}
          <div  className='notas'
                style={{justifyContent:'flex-start', borderTop:'none', borderRadius:'0vh 0vh 1vh 1vh', marginBottom:'10.3vh'}}>
          {CVlist && CVlist.length > 0 ? (
            SortCVlist()
              .filter(reply => SelectList2.includes(reply.name))
              .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', flexDirection:'column', marginBottom: '1vh' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5vh'}}>
                  <button
                    className={ reply.name ===  SelectedCVId ? 'start-button highlighted' : 
                                                reply.Warning === true ? 'start-button yellow' :
                                                SelectList3.includes(reply.name) ? 'start-button green' : 
                                                                                  'start-button'}
                  onClick={() => {
                    setWait(true);
                    setForm('');
                    setScore(reply.stars3);
                    LookCV(reply.id);
                    setScreentype('perfil')
                    setSelectedCVId(reply.name)
                    setSelectedName(reply.name)
                    setselectedRUT(reply.RUT)
                    setTimeout(() => {
                      setWait(false);
                    }, 250);
                  }}
                  >
                    {reply.name}
                  </button>

                  <button className={ !reply.stars3 ? 'middle-button' : 
                                                    JSON.parse(reply.stars3) < 3 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.stars3) < 5 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{maxWidth:'3vw'}}>
                    {reply.stars3 ? JSON.parse(reply.stars3) : ' - '}{'☆'}
                  </button>
                  <input  type='checkbox' 
                          className='select-checkbox'
                          checked={SelectList3 ? SelectList3.includes(reply.name) : false}
                          onChange={() => {
                            Handleselect3(reply.name);
                          }}>
                  </input>
                </div>
                {reply.reference && reply.reference.length > 0 ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '0.5vh' }}>
                    <button 
                      onClick={() => {  setSelectedName(reply.name); 
                                        setReferenceName('');
                                        setReferenceEmail('');
                                        setShowPopup(true); }}
                      style={{ width: '85%' }}>
                      <i className='fa-regular fa-user'></i> &nbsp; Agrega una Referencia
                    </button>
                    {reply.reference.map((ref, index) => (
                      <div style={{display:'flex', flexDirection:'row', width:'85%'}}>
                        <button 
                          key={index} 
                          style={{borderRadius:'1vh 0vh 0vh 1vh', borderRight:'none'}}
                          className={SelectedName === reply.name && SelectedMailR === ref.email ? 'sonbutton highlighted' : 'sonbutton'}
                          onClick={() => { 
                            setSelectedCVId(reply.name)
                            setSelectedName(reply.name)
                            setScreentype('preguntas')
                            setSelectedNameR(ref.name)
                            setSelectedMailR(ref.email)
                            handleformcheck(reply.name, ref.name).then( form =>{
                              setTimeout(() => {
                                setWait(false);
                              }, 250);
                            }) 
                          }}>
                          {ref.name}
                        </button>
                        <button 
                          key={index} 
                          style={{borderRadius:'0vh', width:'20%', justifyContent:'flex-end'}}
                          onClick={() => { 
                            window.open(`https://forms.renee.cl/?NR=${encodeURIComponent(ref.name)}&NC=${encodeURIComponent(reply.name)}`)
                          }}>
                          <i className="fa-solid fa-file-signature"></i> &nbsp; 
                        </button>
                        <button 
                          key={index} 
                          style={{borderRadius:'0vh 1vh 1vh 0vh', width:'20%'}}
                          onClick={() => { 
                            setSelectedCVId(reply.name)
                            setSelectedName(reply.name)
                            setReferenceName(ref.name)
                            setReferenceEmail(ref.email)
                            setShowPopup4(true)
                          }}>
                          <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '0.5vh' }}>
                    <button 
                      onClick={() => { setSelectedName(reply.name); setShowPopup(true); }}
                      style={{ width: '85%' }}>
                      <i className='fa-regular fa-user'></i> &nbsp; Agrega una Referencia
                    </button>
                  </div>
                )}
                </div>
            ))
          ) : (
            <button>No hay candidatos</button>
          )}
          </div>
        </div>
        &nbsp;

        {/* Respuestas Formularios */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginBottom:'-0.2vh'}}>
          {/* top bar */}
          <div style={{display:'flex'}}>
            <h3 style={{display:'flex', alignItems: 'center'}} 
                className='boxtitle'>{SelectedCVId ? SelectedCVId+' | ' : ''}{SelectedNameR ? SelectedNameR+' | ' : ''} 
              <div  className={SelectedCVId? '' : 'hidden'}
                    style={{marginTop:'0'}}>
                <StarRating initialRating={Score} onRatingChange={handleRatingChange} />
              </div>
            </h3>   
            <button className={ SelectedName && ScreenType === 'perfil' ? 'sortbutton On' : 'hidden'}
                    onClick={() => {
                      if (SelectedRUT){
                        setShowPopup2(true);
                      } else {
                        const userRut = window.prompt('Actualmente no contamos con un RUT asignado a este usuario.\nPor favor, ingrese el RUT:');
                        
                        if (userRut) {
                          RutCheck(userRut);
                        } 
                      }
                    }}
                    style = {{  textAlign:'left' ,
                                width:'20%', 
                                padding:'1vh', 
                                borderRadius:'1vh 3vh 0vh 0vh', }}
                    >
              Legal Check
            </button>      
          </div>
          {/* menu opciones */}
          <div  className= { Form.length > 0  ? 'notas' : 'hidden'} 
                style={{ display:'flex', 
                          height: '4vh',
                          gap: '1vh',
                          flexDirection:'row',
                          borderRadius:'0vh 1vh 0vh 0vh',
                          width:'-webkit-fill-available', 
                          overflow:'hidden'}}>
          <button className= { Form.length > 0  ? ' ' : 'hidden'} onClick={AddNota}> <i className='fa-solid fa-plus'></i> &nbsp; Agrega bloque de notas</button> 
          <button className= { Form.length > 0  ? 'Save' : 'hidden'}  onClick={handlesubbmit} style={{width:'200%'}}> <i className='fa-solid fa-floppy-disk'></i> &nbsp; Guardar Cambios</button>
          </div>
          {/* main block */}
          <div  className='notas' 
                style={{  overflow: 'scroll', 
                          overflowX:'hidden', 
                          marginTop:'-4vh',
                          borderRadius:'0vh 0vh 1vh 1vh',
                          borderTop: Form.length > 0  ? 'none' : '',
                          marginBottom: Form.length > 0  ? '14.4vh' : '8vh'}}>
            <div>
              {Wait === true ? (
                <div>
                  <div className='box'>
                    <img
                      src={'/favicon.png'}
                      alt='Profile'
                      style={{
                        width: '150px',
                        height: '150px',
                        objectFit: 'cover',
                        position:'stick'
                      }}/>
                    <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                    <div className='loader-container'>
                      <div  className='pulsing-dot'>
                      <div></div>
                      <div></div>
                      <div></div>
                      </div>
                    </div>
                  </div>
                </div>

              ) /* Formulario Referente */  : Form.length > 0 && ScreenType === 'preguntas' && SelectedName ?  (  
                <div>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                    {Form.map((block, index) => (
                      <div
                        key={index}
                        className='notas'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '1vh',
                          marginRight: '1vh',
                          gap: '1vh',
                          overflow:'hidden',
                          backgroundColor: block.type === 'TextoNotas' ? 'var(--mid-light)' : ''
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex', alignItems: 'baseline', justifyContent:'space-between' }}>
                            <span
                              className='notas-title'
                              style={{ marginLeft: '1vh', fontWeight: 'bold', height:'8vh' }}
                            >
                              {block.title}
                            </span>
                            <button className={block.type === 'TextoNotas' ? 'box-button redb' : 'hidden'} 
                                    onClick={()=>DeletePreunta(index)}>
                            <i className="fa-solid fa-x"></i>
                            </button>
                          </div>
    
                          {block.type === 'Texto' ? (
                            <textarea
                              value={block.content}
                              className='notas'
                              readOnly
                              placeholder='Editar contenido'
                              rows='5'
                              style={{ height: '10vh', marginBottom: '1vh' }}
                            />
                          ) : block.type === 'TextoNotas' ? (
                            <textarea
                              value={block.content}
                              className='notas'
                              onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                              placeholder='Ingrese sus notas'
                              rows='5'
                              style={{ height: '10vh', marginBottom: '1vh' }}
                            />
                          ) : block.type === 'Alt Unica' ? (
                            <div className='alternatives-container'>
                              {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                                <label  key={optIndex} 
                                        className={ alternative.correct ? 'alternative-label green' : 'alternative-label' }>
                                  <input
                                    type='radio'
                                    className='alternative-checkbox'
                                    disabled 
                                    checked={alternative.selected || false}
                                  />
                                  {alternative.label}
                                </label>
                              ))}
                            </div>
                          ) : block.type === 'Alt Multiple' ? (
                            <div className='alternatives-container'>
                              {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                                <label  key={optIndex} 
                                        className={ alternative.correct ? 'alternative-label green' : 'alternative-label' }>
                                  <input
                                    type='checkbox'
                                    className='alternative-checkbox'
                                    disabled 
                                    checked={alternative.selected || false}
                                  />
                                  {alternative.label}
                                </label>
                              ))}
                            </div>
                          ) : null}
                          
                        </div>      
                      </div>
                    ))}
                  </div>
                </div>
              ) /* Perfil Candidato */      : ScreenType === 'perfil' && SelectedName ? (
                <div style={{display:'flex', gap:'1vh'}}>
                  <div className='boxfit' style={{ maxWidth: '20vw' }}>
                    <div className='notas'>
                      <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <img
                          src={CV && CV.personalInfo.picture ? CV.personalInfo.picture : '/default.png'}
                          alt='Profile'
                          style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'cover',
                            borderRadius: '50%',
                            position:'stick'
                          }}
                        />
                        <div
                          dangerouslySetInnerHTML={{ __html: CV ? FormatPersonalData(CV) : '' }}
                          style={{
                            whiteSpace: 'pre-wrap',
                            textAlign:'start'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className= { ScreenType === 'perfil' ? 'notas' : 'hidden'}
                    dangerouslySetInnerHTML={{ __html: CV ? FormatJobData(CV.workExperience) 
                                                          + '\n\n\n\n'
                                                          + FormatStudyData(CV.studies) : '' }}
                    style={{
                      whiteSpace: 'pre-wrap',
                      overflowY: 'auto',
                      textAlign: 'left'
                    }}
                  />
                </div>
              ) /* Sin respuesta */         : !Form.length && SelectedName ? (
                <div style={{display:'flex', flexDirection:'column',alignItems:'center', fontSize: '1.2rem'}}> 
                  <h2>Sin respuesta disponible</h2>
                  <p>Recuerde enviar el formulario utilizando el boton de reenviar</p>
                  <p>O si ya lo enviaste, puedes reenviar el formulario con el mismo boton </p>
                  <p>Alternativamente puedes </p>
                  <button onClick={()=>{window.open(`https://forms.renee.cl/?NR=${encodeURIComponent(SelectedNameR)}&NC=${encodeURIComponent(SelectedName)}`)}}
                          style={{width: '30%'}}>
                            <i className="fa-solid fa-file-signature"></i> &nbsp; Rellenar el Formulario manualmente
                  </button>
                  <p>O si lo consideras necesario </p>
                  <button className='redb'
                          onClick={deleteReference} 
                          style={{width: '30%'}}>
                            <i className="fa-solid fa-x"></i> &nbsp; Eliminar al Referente
                  </button>
                </div>
              
              ) /* Pantalla Default */      : (
                <h2
                  className={CV ? 'hidden' : ''}> 
                  Inicia seleccionando un candidato o un referente
                  {/* eslint-disable-next-line */}
                  <p style={{fontSize:'4rem'}}>👈</p>
                </h2>
              )}
            </div>
          </div>
        </div>
      </div>
        
      <div className='bottom'>
        <button onClick={goto09}>volver</button>
        {/* <button className='Save last-button' onClick={goto11}>Guardar y Continuar</button> */}
      </div>
    </div>
  );
}
