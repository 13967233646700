
//=============================================================================
// Pantalla Generación Oferta/ Preguntas | Publicación
//=============================================================================

import './css/App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import { jsPDF } from 'jspdf';
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Public() {

  const navigate = useNavigate();
  const [Oferta, setOferta] = useState('');
  const [Final1, setFinal1] = useState('');
  const [Final2, setFinal2] = useState('');
  const [Final3, setFinal3] = useState('');
  const [Final4, setFinal4] = useState('');
  const [Portal, setPortal] = useState('');
  const [Pregunta1, setPregunta1] = useState('');
  const [Pregunta2, setPregunta2] = useState('');
  const [Pregunta3, setPregunta3] = useState('');
  const [Pregunta4, setPregunta4] = useState('');
  const [SelectedJID, setSelectedJID] = useState('');
  const [instruccion, setInstruccion] = useState('');
  const [FetchedData, setFetchedData] = useState(''); 
  const [IDlist,setIDlist] = useState([]);
  const [Claves, setClaves] = useState(['']);
  const [Wait, setWait] = useState(false);
  const [Wait2, setWait2] = useState(false);
  const [Regen, setRegen] = useState(false);
  const [Aviso, setAviso]     = useState(false);
  const [ShowPopup, setShowPopup]     = useState(false);
  const [ShowPopup2, setShowPopup2]     = useState(false);
  const [ShowPopup3, setShowPopup3]     = useState(false);
  const [ShowPopup4, setShowPopup4]     = useState(false);

  const [FileName, setFileName]           = useState(''); 
  const [inputKey, setInputKey]           = useState(Date.now());
  const FileInputRef                      = useRef(null);

  const handleChange = (event) => { setOferta(event.target.value) };

  const Notas1 = 'Genera En el titulo utiliza el formato : '
                  + 'Buscamos {nombre del cargo} para compañía de {industria donde se busca al candidato}.'
                  + 'Genera El aviso como un proceso de búsqueda para la empresa' 
                  + sessionStorage.getItem('grupo')
                  + 'En el texto generado refiere a la empresa como "nuestro cliente" en lugar de su nombre';

  const Notas2 = 'Genera El aviso como un proceso de busqueda para una empresa externa' 
                  + 'Mantieniendop un lenguaje formal del estilo "la empresa"';

  const Notas3 = 'Genera El aviso como un proceso de busqueda para una empresa incognita' 
                  + 'evita cualquier mencion del nombre de la empresa';


  const Export = () => {

    if (SelectedJID.includes('YP_')) {
      ExportToYAPO();
    } else if (SelectedJID.includes('renee')) {
      window.alert('Este es un proceso interno, no se puede actualizar');
    } else {  
      UpdateTrabajando();
    }

    setShowPopup3(false);
  }

  async function ExportToTrabajando() {
    await SaveData()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_create',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      SaveJobId(reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 250);

    setShowPopup4(false);
  };

  async function ExportToYAPO() {
    await SaveData()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'YP_create',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      SaveJobId('YP_'+reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 250);

    setShowPopup4(false);
  };

  async function FetchData () {
    const data = await APICALL({
      apicall :'PD_fetch' ,
      code :sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo')
    });
    setOferta(data.GPToferta);
    if (!data.GPToferta){
      GenerateOferta();
    } 
    if (!data.GPTpreguntas){
      GeneratePreguntas();
    }
  };

  async function GenerateOferta(notas) {
    setWait(true);
    setOferta('Espera un momento mientras Reneé Trabaja ...')
    try {
      const newText = await AICALL({
        apicall: '02',
        data: notas 
              + JSON.stringify(FetchedData.basic_data)
              + '. Perfil de busqueda: '
              + localStorage.getItem('reply') 
              + '. Notas Adicionales del cargo : '
              + localStorage.getItem('notas')  
              + '. Notas Adicionales de la empresa : '
              + localStorage.getItem('notasemp')
              + '. Informacion anexa : '
              + localStorage.getItem('archivo')
      });
      setOferta(FormatResponse(JSON.parse(newText.reply)));
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 250);
  };

  async function GeneratePreguntas() {
    setWait2(true);
    if (!Final1){
      setPregunta1('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final2){
      setPregunta2('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final3){
      setPregunta3('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final4){
      setPregunta4('Espera un momento mientras Reneé Trabaja ...')
    }
    
    try {
      const newText = await AICALL({
        apicall: '03',
        data: localStorage.getItem('reply') 
              + '. Notas Adicionales del cargo : '
              + localStorage.getItem('notas')  
              + '. Notas Adicionales de la empresa : '
              + localStorage.getItem('notasemp')
              + '. Informacion anexa : '
              + localStorage.getItem('archivo')
              + 'no repitas estas preguntas : ' + Pregunta1 + Pregunta2 + Pregunta3 + Pregunta4 
      });
    
      const parsedReply = JSON.parse(newText.reply);
    
      if (!Final1) {
        setPregunta1(parsedReply.Pregunta_1);
      }
      if (!Final2) {
        setPregunta2(parsedReply.Pregunta_2);
      }
      if (!Final3) {
        setPregunta3(parsedReply.Pregunta_3);
      }
      if (!Final4) {
        setPregunta4(parsedReply.Pregunta_4);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    
    setTimeout(() => {
      setWait2(false);
    }, 250);
  };

  async function HandleIterateText() { 
    if (Aviso){
      setWait(true);
    } else {
      setWait2(true);
    }
    // console.log(text);
    try {
      const response = await AICALL({
        apicall: Aviso ? '02' : '03',
        data: Aviso ? `${Oferta} ${instruccion}` 
                    : `${Pregunta1} ${Pregunta2} ${Pregunta3} ${Pregunta4} ${instruccion}`
                    + 'Asegurate de mantener el mismo formato que el texto original sin agregar elementos adicionales'
                    + '. Notas Adicionales del cargo : '
                    + localStorage.getItem('notas')  
                    + '. Notas Adicionales de la empresa : '
                    + localStorage.getItem('notasemp')
                    + '. Informacion anexa : '
                    + localStorage.getItem('archivo')

      });
      if ( Aviso ) {
        setOferta(FormatResponse(JSON.parse(response.reply)));
      } else {
        const parsedReply = JSON.parse(response.reply)
        setPregunta1(parsedReply.Pregunta_1);
        setPregunta2(parsedReply.Pregunta_2);
        setPregunta3(parsedReply.Pregunta_3);
        setPregunta4(parsedReply.Pregunta_4);
      }
      setInstruccion('');
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 1000);;
  };

  async function DropContent() {
    setInputKey(Date.now());
    setFileName('');
  };

  async function HandleFileChange(event) {
    const image = event.target.files[0];
    if (image) {
      setFileName(image.name); 
    } else {
      return
    }
  
    const fileMimeType = image.type; 
    console.log(fileMimeType);
  
    if (fileMimeType === 'image/png' || fileMimeType === 'image/jpeg' || fileMimeType === 'image/webp' ) {

      const data = new FormData();

      data.append('apicall', 'FX_01'); 
      data.append('P_name', sessionStorage.getItem('name').replace(/\|/g, '').replace(/ /g, '_').replace(/__/g, '_')); 
      data.append('file', image); 

      
      for (let [key, value] of data.entries()) {
        console.log(key, value);
      }



      try {
        
        await APICALL(data);

      } catch (error) {
        console.error('Error procesando la imagen', error);
      }

    } else {
      DropContent()
      window.alert('Formato no soportado. Actualmente solo se aceptan los formatos:\n * .Png\n *.Jpeg\n *.webp');
    }
  };

  async function SaveData() {
    setWait(true);
    setWait2(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'), 
        Claves: Claves,
        GPToferta: Oferta,
        GPTpreguntas: [Pregunta1.replace(/"/g, '') , Pregunta2.replace(/"/g, '') , Pregunta3.replace(/"/g, '') , Pregunta4.replace(/"/g, '')]
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }  finally {
      setTimeout(() => {
        setWait(false);
        setWait2(false);
      }, 250); 
    }
  };

  async function SaveJobId(ID) {
    if (!IDlist.includes(ID)) {
      IDlist.push(ID);
    }
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        JobId: IDlist.filter(item => {return item !== null && item !== undefined && item !== ''})
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function UpdateTrabajando() {
    await SaveData()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_update',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo'),
        JobId: SelectedJID
      });
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 250);
  };

  // const exportToPDFoferta = () => {
  //   const doc = new jsPDF();

  //   doc.text(oferta, 10, 10);
  //   doc.save('oferta.pdf');
  // };

  const FormatResponse = (jobDetails) => {
    
    let formattedText = '';

    formattedText += jobDetails.Descripcion_de_la_empresa+ '\n\n';
  
    formattedText += `**Misión del Cargo:**\n${jobDetails.Mision_del_cargo}\n\n`;
  
    formattedText += `**Responsabilidades:**\n`;
    jobDetails.Responsabilidades.forEach((responsabilidad, index) => {
      formattedText += `~${responsabilidad}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Requisitos Clave:**\n`;
    jobDetails.Claves.forEach((clave, index) => {
      formattedText += `~${clave}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Oferta:**\n`;
    formattedText += `~Banda Salarial: ${jobDetails.Oferta.banda_salarial}~\n`;
    formattedText += `~Horario: ${jobDetails.Oferta.horario}~\n`;
    formattedText += `~Modalidad de Trabajo: ${jobDetails.Oferta.modalidad_de_trabajo}~\n`;
    formattedText += `~Beneficios Adicionales: ${jobDetails.Oferta.beneficios_adicionales}~\n`;
    formattedText += `\n`;

    formattedText += jobDetails.Captura + '\n';

    let formatedclave = ''
    jobDetails.Claves.forEach((clave, index) => {
      formatedclave += `~${clave}~\n`;
    });
    setClaves(formatedclave);
    return formattedText;
  };

  const HandleFile = () => {
    FileInputRef.current.click();
  };

  const goto05 = () => { navigate('/P_Perfil') };
  const goto07 = () => { navigate('/P_Candidatos') };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      setIDlist(data.JobId || []);
      setFetchedData(data);
      setClaves(data.Claves)
      if (data.GPToferta){
        setOferta(data.GPToferta);
      } else {
        GenerateOferta();
      }
      if (data.GPTpreguntas){
        
        setPregunta1(data.GPTpreguntas[0]);
        setPregunta2(data.GPTpreguntas[1]);
        setPregunta3(data.GPTpreguntas[2]);
        setPregunta4(data.GPTpreguntas[3]);
      } else {
        GeneratePreguntas();
      }
      
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const dataItems = [
    { label: 'CARGO',                   value: FetchedData.Charge_name 	? FetchedData.Charge_name.toUpperCase() : '' }, 
    { label: 'EMPRESA',                 value: FetchedData.E_name 			? FetchedData.E_name.toUpperCase() : '' }, 
    { label: 'INTERLOCUTOR',            value: FetchedData.I_name 			? FetchedData.I_name.toUpperCase() : '' },
		{ label: 'PARTNER ENCARGADO',       value: FetchedData.R_name 		  ? FetchedData.R_name.toUpperCase() : '' }
  ];

  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      {/* Popup Iteracion */}
      <div className={ShowPopup ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup ? 'warningbox' : 'hidden'}>
          <h2>Instrucciones Adicionales {Aviso ? 'Aviso' : 'Preguntas'}:</h2>
          <p style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            Ingresa cualquier instrucción adicional que quieras que Renee tenga en cuenta al generar la nueva iteracion {Aviso ? 'del Aviso' : 'de las Preguntas'}.
          </p>
          <textarea
            className='notas'
            style={{marginLeft:'2vh', marginRight:'2vh'}}
            value={instruccion}
            onChange={(e) => setInstruccion(e.target.value)}>
          </textarea>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {HandleIterateText( instruccion ); setShowPopup(false) }}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Generar Iteracion </button>
            <button onClick={() => setShowPopup(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Publicacion */}
      <div className={ShowPopup2 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup2(false)} >
        </div>
        <div className={ShowPopup2 ? 'warningbox' : 'hidden'} style={{height:'60vh'}}>
          <h2>Publica tu Oferta:</h2>
          <p style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {IDlist.length === 0 ? 'Vamos a publicar tu Oferta generada, puedes elegir entre los siguientes portales de empleo'
                        : 'Esta Oferta ya fue publicada previamente en estos portales : '}
          </p>
          <p className={IDlist.length === 0 ? 'hidden' : ''} style={{display:'flex', flexDirection:'column',gap:'1vh'}}>
            {IDlist.map((item, index) => (
                <div key={index}>
                  <div style={{display:'flex'}}>
                    <div className='data-box' style={{width:'30vh', borderRadius:'1vh 0vh 0vh 1vh', borderRight:'none'}}>
                       {String(item)?.includes("renee") ? 'Publicacion interna' : String(item)?.includes("YP_") ? 'Publicacion en Yapo' : 'Publicacion en Trabajando'} 
                    </div>
                    <button className={'data-box'} 
                            onClick={() => {setShowPopup2(false); setShowPopup3(true); setSelectedJID(item)}}
                            style={{width:'50vh', borderRadius:'0vh 1vh 1vh 0vh'}}> {item} </button>                  </div>
                </div>
            ))}
          </p>
          <p className={IDlist.length === 0 ? 'hidden' : ''} style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {'Aun asi puedes elegir re-publicar tu Oferta en uno los siguientes portales de empleo'} <br/><br/>
            {IDlist.length < 3  ? `Aún tienes ${3 - IDlist.length} publicaciones de oferta disponibles` 
                                : ' No tienes espacion de publicacion de oferta disponibles ' } <br/><br/>
            {'Recuerda que puedes elegir actualizar tu oferta'} &nbsp;
            <button onClick={() => {setShowPopup2(false); setShowPopup3(true)}} style={{width:'25%', display: 'inline'}}> Presionando aqui</button>
          </p>

          <div style={{display:'flex', gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button className = {IDlist.length < 3 ? '' : 'hidden'}
                        onClick={() => {setShowPopup4(true); setPortal('Trabajando')}}>Publicar en TRABAJANDO.COM</button>
            <button className = {IDlist.length < 3 ? '' : 'hidden'}
                        onClick={() => {setShowPopup4(true); setPortal('Yapo')}}>Publicar en YAPO.CL</button>
            <button onClick={() => setShowPopup2(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Actualizacion */}
      <div className={ShowPopup3 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => {setShowPopup3(false); setSelectedJID('')}} >
        </div>
        <div className={ShowPopup3 ? 'warningbox' : 'hidden'}>
          <h2>Actualiza tu Oferta:</h2>
          <p style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {IDlist.length === 0 ? 'Hey parece que no has publicado tu Oferta todavia'
                        : 'Ahora que tienes tu Oferta lista selecciona el portal en el que quieres actualizar : '}
          </p>
          <p className={IDlist.length === 0 ? 'hidden' : ''} style={{display:'flex', flexDirection:'column',gap:'1vh'}}>
            {IDlist.map((item, index) => (
                <div key={index} >
                  <div style={{display:'flex'}}>
                    <div className='data-box' style={{width:'30vh', borderRadius:'1vh 0vh 0vh 1vh', borderRight:'none'}}>
                       {String(item)?.includes("renee") ? 'Publicacion interna' : String(item)?.includes("YP_") ? 'Publicacion en Yapo' : 'Publicacion en Trabajando'} 
                    </div>
                    <button className={SelectedJID === item ? 'data-box highlighted' : 'data-box'} 
                            onClick={() => setSelectedJID(item)}
                            style={{width:'50vh', borderRadius:'0vh 1vh 1vh 0vh'}}> {item} </button>
                  </div>
                </div>
            ))}
          </p>
          <p className={IDlist.length === 0 ? 'hidden' : ''} style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {'Ahora solo presiona confirmar para actualizar tu Oferta en el portal seleccionado'} 
          </p>
          
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button className = {SelectedJID ? '' : 'hidden'}
                        onClick={Export}>Confirmar</button>
            <button onClick={() => {setShowPopup3(false); setSelectedJID('')}}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Detalles */}
      <div className={ShowPopup4 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => {setShowPopup4(false); setSelectedJID('')}} >
        </div>
        <div className={ShowPopup4 ? 'warningbox' : 'hidden'}>
          <h2>Publica tu oferta por {Portal}</h2>
          <p className={Portal === 'Trabajando' ? '' : 'hidden'} style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            Trabajando.com es una plataforma enfocada en perfiles profesionales y técnicos, ideal para buscar candidatos con educación superior o experiencia especializada. <br/><br/>
            ✔ Postulantes con formación en áreas como administración, ingeniería, TI y más.<br/>
            ✔ Ideal para empleos con requisitos específicos y cargos profesionales.<br/>
            ✔ Procesos de selección más estructurados. <br/><br/>
            ¿Estás seguro de que este es el portal adecuado para tu Oferta?
          </p>
          <p className={Portal === 'Yapo' ? '' : 'hidden'} style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            Yapo.cl es una plataforma más rápida y directa, ideal para encontrar trabajadores operativos y temporales <br/> sin tantos filtros en el proceso de selección. <br/><br/>
            ✔ Enfocado en empleos en ventas, logística, construcción, servicios y más.<br/>
            ✔ Postulación rápida y contacto directo con candidatos. <br/>
            ✔ Menos requisitos formales, más alcance para empleos prácticos <br/><br/>
            ¿Estás seguro de que este es el portal adecuado para tu Oferta?
          </p>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button className={Portal === 'Trabajando' ? '' : 'hidden'} onClick={ExportToTrabajando}>Confirmar publicacion por TRABAJANDO</button>
            <button className={Portal === 'Yapo' ? '' : 'hidden'} onClick={ExportToYAPO}>Confirmar publicacion por YAPO</button>
            <button onClick={() => {setShowPopup4(false); setSelectedJID('')}}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Type Header */}
      <div className='typing-effect-container'>
				<h2 className='typing-effect' > 
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          😍 También he creado para ti este AVISO DE EMPLEO. Puedes editarlo o pedirme que lo genere nuevamente
        </h2>
			</div>
      
      <div className='MainBody'>

        {/* Aviso Generado */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginRight: '1vh', marginBottom: '-0.4vh'}}>
          <h3 className = 'boxtitle'>Aviso Generado</h3>
          { Wait === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <textarea className='notas'
              value={Oferta}
              onChange={handleChange}
              spellCheck />
            )
          }
        </div>

        {/* Preguntas Generadas */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginRight: '1vh', marginBottom: '-0.4vh'}}>
          <h3 className = 'boxtitle'>Preguntas de Aviso</h3>
          { Wait2 === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <div className='notas' style={{overflow:'hidden', gap:'-2vh', paddingTop:'2vh', alignItems:'flex-end' }}>

                <textarea className={Final1 ? 'preguntas preguntasSaved' : 'preguntas'}
                  value={Pregunta1.replace(/"/g, '')}
                  placeholder='espere mientras se genera el contenido...'
                  onChange={(event) => { let newValue = event.target.value ; setPregunta1(newValue) }}/>
                <button style={{position:'relative', width:'4vh', top:'-14vh', left:'0.5vh'}} 
                        onClick={() => {  if (Final1 === '') {
                                            setFinal1(Pregunta1)
                                          } else {
                                            setFinal1('')
                                          }}}> 
                    {Final1 === '' ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i> 
                    )}
                </button>

                <textarea className={Final2 ? 'preguntas preguntasSaved' : 'preguntas'}
                  value={Pregunta2.replace(/"/g, '')}
                  placeholder='espere mientras se genera el contenido...'
                  onChange={(event) => { let newValue = event.target.value ; setPregunta2(newValue) }}/>
                <button style={{position:'relative', width:'4vh', top:'-14vh', left:'0.5vh'}}
                        onClick={() => {  if (Final2 === '') {
                                            setFinal2(Pregunta2)
                                          } else {
                                            setFinal2('')
                                          }}}> 
                    {Final2 === '' ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i> 
                    )}
                </button>    
                <textarea className={Final3 ? 'preguntas preguntasSaved' : 'preguntas'}
                  value={Pregunta3.replace(/"/g, '')}
                  placeholder='espere mientras se genera el contenido...'
                  onChange={(event) => { let newValue = event.target.value ; setPregunta3(newValue) }}/>
                <button style={{position:'relative', width:'4vh', top:'-14vh', left:'0.5vh'}}
                        onClick={() => {  if (Final3 === '') {
                                            setFinal3(Pregunta3)
                                          } else {
                                            setFinal3('')
                                          }}}> 
                    {Final3 === '' ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i> 
                    )}
                </button>

                <textarea className={Final4 ? 'preguntas preguntasSaved' : 'preguntas'}
                  value={Pregunta4.replace(/"/g, '')}
                  placeholder='espere mientras se genera el contenido...'
                  onChange={(event) => { let newValue = event.target.value ; setPregunta4(newValue) }}/>
                <button style={{position:'relative', width:'4vh', top:'-14vh', left:'0.5vh'}}
                        onClick={() => {  if (Final4 === '') {
                                            setFinal4(Pregunta4)
                                          } else {
                                            setFinal4('')
                                          }}}> 
                    {Final4 === '' ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className='fa-solid fa-lock'></i> 
                    )}
                </button>                
              </div>
            )
          }
        </div>

        {/* Menu Lateral */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='data-boxes'>

          {/* Data del proceso */}
          {dataItems.map((item, index) => (
            <div key={index} className='data-box'>
              <strong>{item.label}:</strong> {item.value}
            </div>
          ))}

          {/* Adjuntado de Imagen  */}
          <div style={{display: 'flex' , gap : '0.5vh'}}>
            <button style={{width:'150%'}} onClick={HandleFile}>
            <i className="fa-solid fa-paperclip"></i>  &nbsp; {FileName || 'Adjuntar Imagen'}
            </button>
            <input  key = {inputKey}
                    id = 'fileContent'
                    type='file'  
                    onChange={HandleFileChange}
                    ref={FileInputRef} 
                    className='hidden'/>
            
            <button  className='redb' onClick={DropContent}>
            <i className="fa-solid fa-x"></i> &nbsp; Eliminar Archivo</button>
          </div>

          {/* Botones */}
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', columnGap:'0.5vh', rowGap:'0.5vh'}}>

            <button className='Save' style={{height:'6vh'}} onClick={() => {setRegen(!Regen)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Regenerar Aviso </button>
            <button className='Save' style={{height:'6vh'}} onClick={GeneratePreguntas}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Regenerar Preguntas </button>
            
            <button className = {Regen ? 'SubButon' : 'hidden'}
                    onClick={() => GenerateOferta()}> Regenerar nuevo Aviso Laboral</button>
            <button className = {Regen ? 'SubButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas1)}>Regenerar como Consultora</button>
            <button className = {Regen ? 'SubButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas2)}>Regenerar como Empresa Contratante</button>
            <button className = {Regen ? 'SubButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas3)}>Regenerar como Empresa Confidencial</button>

            <button className='Save' style={{height:'6vh'}} onClick={() => {setShowPopup(true); setAviso(true)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Iterar Aviso </button>
            <button className='Save' style={{height:'6vh'}} onClick={() => {setShowPopup(true); setAviso(false)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Iterar Preguntas </button>

            <button 
                    style={{height:'6vh'}}
                    onClick={SaveData}> <i className='fa-solid fa-floppy-disk'></i> &nbsp; Guardar Todo </button>
            <button 
                    style={{height:'6vh'}}
                    onClick={FetchData}> <i className='fa-solid fa-rotate-left'></i> &nbsp; Deshacer Cambios </button>

            <button 
                    style={{height:'6vh'}}
                    onClick={() => {setShowPopup2(true)}}>Publicar la Oferta</button>

            <button  
                    style={{height:'6vh'}}
                    onClick={() => {setShowPopup3(true)}}>Actualizar la Oferta</button>   
                      
          </div>
        </div>
      </div>

      <div className='bottom'>
        <button onClick={goto05}>volver</button>
        <button className={IDlist.length > 0 ? 'Save last-button' : 'hidden'} onClick={() => {SaveData(); goto07()}}>Guardar y Continuar</button>
      </div>
    </div>
  );
}
